import styled from 'styled-components'
import { Box, Menu, type BoxProps } from '@mui/material'
import { Colors, from } from 'styles'

import globalBackground from 'assets/images/layout/defaultLayout/background.webp'

export const DefaultContainerStyles = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  height: auto;
  min-height: 100%;
  min-width: 100%;
  background: url(${globalBackground}) no-repeat center center fixed;
  background-size: cover;
`

export const DefaultHeaderStyles = styled.header`
  z-index: 10;
  pointer-events: all;

  width: 100%;
  padding: 1rem 3rem;
  justify-content: space-between;
  display: flex;
  gap: 1.6rem;
`
export const DefaultHeaderLeftStyle = styled.div`
  display: flex;
  gap: 1.6rem;
`
export const DefaultHeaderRightStyle = styled.div`
  display: flex;
  gap: 1.6rem;
  justify-content: space-between;
`

export const DefaultMainStyles = styled(Box)<BoxProps>`
  display: flex;
  z-index: 2;

  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;

  overflow: hidden;

  svg:not(.MuiSvgIcon-root) {
    width: 100%;
    height: auto;
    display: none;
    z-index: 1;
    min-width: 30rem;

    :first-child {
      transform: translateX(calc(22vw - 100%));
    }

    :last-child {
      transform: translateX(calc(-22vw + 100%));
    }

    ${from.lg} {
      :first-child,
      :last-child {
        transform: none;
      }
    }
  }

  ${from.md} {
    display: grid;
    grid-template-columns: 1fr 2fr 1fr;
    grid-template-rows: 1fr;
    grid-template-areas: 'left center right';
    grid-column-gap: 1rem;
    svg:not(.MuiSvgIcon-root) {
      pointer-events: none;
      display: block;
      :first-child {
        grid-area: left;
      }
      :last-child {
        grid-area: right;
      }
    }
  }
`

export const DefaultFormAreaStyles = styled.div`
  width: 100%;
  margin: 0 auto;
  max-width: 75rem;

  display: flex;
  justify-self: center;
  align-self: center;

  color: ${Colors.white};

  margin: 1rem;
`

export const DefaultFooterStyles = styled.footer`
  z-index: 2;

  width: 100%;
  padding: 1rem 3rem;

  display: flex;
  justify-content: center;
  align-items: center;

  .MuiIconButton-root {
    display: none;
  }

  ${from.xs} {
    .MuiIconButton-root {
      display: block;
      position: absolute;
      right: 3rem;
      left: auto;
    }
  }
`

export const DefaultHeaderMenuStyles = styled(Menu)`
  .MuiPaper-root {
    background: transparent;
  }
`

export const FormLayoutBackgroundStyles = styled.div`
  background: ${Colors.black}90 0 0 no-repeat padding-box;
  border-radius: 1rem;
  width: 100%;

  ${from.sm} {
    max-width: 75vw;
  }

  ${from.md} {
    max-width: 55vw;
  }

  ${from.lg} {
    max-width: 45rem;
  }
`

export const ViewLayoutStyles = styled.div`
  padding: 2.4rem 5.2rem 4.4rem;
`
