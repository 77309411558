import numetryLogo from 'assets/images/company/numetry-logo-nb.png'
import SignInPassword from 'assets/audios/nb/NB-signin-password.mp3'
import SignInUsername from 'assets/audios/nb/NB-signin-username.mp3'
import SignUpUsername from 'assets/audios/nb/NB-signup-username.mp3'
import SignUpPassword from 'assets/audios/nb/NB-signup-password.mp3'
import SignUpReview from 'assets/audios/nb/NB-signup-review.mp3'
import Character from 'assets/audios/nb/NB-choose-your-character.mp3'

import { PagesLocaleTypes } from '../locales.types'

const pagesLocaleNb: PagesLocaleTypes = {
  landing: {
    imPlayingFrom: `Velg om du skal spille på skolen eller på fritiden`,
    modality: {
      school: 'Skolen',
      freeTime: 'Fritiden'
    }
  },
  home: {
    logo: numetryLogo,
    newUser: 'Ny bruker',
    signIn: 'Logg inn',
    haveYouPlayed: 'Har du spilt Numetry før?',
    logInWithFeide: 'Logg inn med Feide',
    logInWithoutFeide: 'Logg inn uten Feide',
    createNewUser: 'Lag ein ny brukar',
    doesNotHaveFeide: `Har ikke skolen din Feide-pålogging i Numetry?\n\n Kontakt oss på [{{ email }}](mailto:{{ email }}?subject=Tilgang%20til%20Feide) for å komme i gang gratis i dag.`,
    or: 'eller',
    footer: {
      aGameBy: 'Et spill fra'
    }
  },
  unsuportedDevice: {
    title:
      'Numetry kan foreløpig kun spilles på PC/Chromebook/Mac eller via vår iPad-app.',
    button: 'Åpne Numetry i iPad-appen.',
    footer: 'Hvis du ikke har appen. <0>Last den ned fra appbutikken.</0>'
  },
  logout: {
    loggingOut: 'Logger ut...'
  },
  auth: {
    validationError: {
      requiredField: 'Obligatorisk felt'
    },
    redirectToGame: {
      redirectingToGame: 'Omdirigerer til spillet...',
      continueAs: 'Fortsett som:',
      yes: 'Ja',
      noLogOut: 'Nei, logg ut'
    },
    character: {
      contextButton: {
        playNumetry: 'Spill Numetry'
      },
      title: 'Velg din karakter.',
      audio: Character,
      input: {
        placeholder: 'Gi et navn til din karakter'
      },
      buttons: {
        save: {
          value: 'Lagre'
        },
        clearName: {
          value: 'Navn klart!'
        },
        change: {
          value: 'Endre'
        }
      }
    },
    signUp: {
      usernameInUse: 'Brukernavnet er allerede i bruk, velg et annet.',
      usernameCannotBeUsed: `Dette brukernamnet kan ikkje bli brukt.`,
      usernameStep: {
        audio: SignUpUsername,
        beforeYouCanStart:
          'Hei! Før du kan begynne å spille må du lage en bruker.',
        usernameAlreadyTaken: 'Brukernavn allerede tatt. Forslag:',
        username: 'Brukernavn'
      },
      passwordStep: {
        audio: SignUpPassword,
        alsoCreatePassword: 'Du må også lage et passord.',
        password: 'Passord',
        passwordRepeat: 'Samme passord som over:',
        inputPlaceholder: 'Passord med minst {{ minPasswordLength }} tegn',
        minimumPasswordCharacters:
          'Passordet ditt må inneholde minst {{ minPasswordLength }} tegn.',
        passwordNotEqual: 'Passordene må være like.',
        togglePasswordVisibility: 'Vis passord'
      },
      reviewStep: {
        audio: SignUpReview,
        greatRememberThis:
          'Flott! Dette må du huske for å logge inn neste gang. Skriv det ned!',
        username: 'Brukernavn:',
        password: 'Passord:',
        iveWrittenItDown: 'Jeg har skrevet det ned'
      }
    },
    signIn: {
      usernameStep: {
        audio: SignInUsername,
        enterYourUsername: 'Skriv inn brukernavnet ditt for å logge inn.',
        enterYourUsernameInput: 'Skriv inn brukernavnet ditt',
        notPlayedBefore: 'Ikke spilt Numetry før?',
        createNewUser: 'Opprett ny bruker',
        usernameError:
          'Vi fant ikke dette brukernavnet. Prøv igjen. Har du glemt brukernavnet ditt, må du lage en ny bruker.'
      },
      passwordStep: {
        audio: SignInPassword,
        youMustEnterYourPassword: 'Du må også skrive inn passordet ditt.',
        passwordWithMinCharacter:
          'Passord med minst {{ minPasswordLength }} tegn',
        passwordError:
          'Brukernavn eller passord samsvarer ikke med en gyldig konto.'
      }
    }
  },
  municipality: {
    getStartedWithFeide: 'Kom i gang med Feide gratis'
  }
}

export default pagesLocaleNb
