import React, { useContext } from 'react'
import { IconButton } from '@mui/material'
import { MusicNoteSharp, MusicOffSharp } from '@mui/icons-material'

import { LANGUAGES_DATA } from 'i18n'

import { AudioContext } from 'context'

import { ButtonComponent, LanguageSelectorComponent } from 'components'

import { useTranslation } from 'react-i18next'
import {
  DefaultHeaderLeftStyle,
  DefaultHeaderRightStyle,
  DefaultHeaderStyles
} from './Default.styles'

export const DefaultHeaderComponent: React.FC = () => {
  const { t } = useTranslation()
  const { isPlayingMusic, stopMusic, playMusic } = useContext(AudioContext)

  const marketingPage =
    process.env.REACT_APP_MARKETING_URL || 'https://www.numetrygame.com/'
  const teacherLogin =
    process.env.REACT_APP_TEACHER_PORTAL_URL || 'https://teacher.numetry.com/'
  const contactPage =
    process.env.REACT_APP_CONTACT_URL || 'https://www.numetrygame.com/Kontakt/'

  return (
    <DefaultHeaderStyles>
      <DefaultHeaderLeftStyle>
        <a href={marketingPage}>
          <ButtonComponent
            value={t('general.actions.back')}
            variant='contained'
            size='small'
            mode='primaryDark'
          />
        </a>
        <a href={teacherLogin}>
          <ButtonComponent
            value={t('navigation.toTeacherPortal.title')}
            variant='contained'
            size='small'
            mode='pink'
          />
        </a>
        <a href={contactPage}>
          <ButtonComponent
            value={t('navigation.toContactPage.title')}
            variant='contained'
            size='small'
            mode='secondaryAlt'
          />
        </a>
      </DefaultHeaderLeftStyle>
      <DefaultHeaderRightStyle>
        <LanguageSelectorComponent languages={LANGUAGES_DATA} />
        <IconButton
          color='primary'
          size='large'
          onClick={() => (isPlayingMusic ? stopMusic() : playMusic())}
          aria-label='play music'
        >
          {isPlayingMusic ? <MusicOffSharp /> : <MusicNoteSharp />}
        </IconButton>
      </DefaultHeaderRightStyle>
    </DefaultHeaderStyles>
  )
}
