export default {
  home: {
    title: 'Hjem'
  },
  signUp: {
    title: 'Melde deg på',
    username: {
      title: 'Brukernavn'
    },
    password: {
      title: 'Passord'
    },
    review: {
      title: 'Anmeldelse'
    }
  },
  signIn: {
    title: 'Logg inn'
  },
  character: {
    title: 'Karakter'
  },
  logout: {
    title: 'Logg ut'
  },
  municipality: {
    title: 'Kommune'
  },
  toTeacherPortal: {
    title: 'Lærarportalen'
  },
  toContactPage: {
    title: 'Kontakt oss'
  }
}
